<template>
  <div></div>
<!--  <div>-->
<!--    <div class="table-title">工人实名制信息清单</div>-->
<!--    <table border="1">-->
<!--      <tr>-->
<!--        <td class="tda">序号</td>-->
<!--        <td class="tde" colspan="2">广肇高速改扩建CZTJ1标</td>-->
<!--        <td class="tdc">2023.02.03</td>-->
<!--        <td class="tdc">中铁大桥局集团</td>-->
<!--      </tr>-->
<!--      <tr class="part-title">-->
<!--        <td colspan="5">1.工人备案信息</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.1</td>-->
<!--        <td class="tde" colspan="2">身份证号码：123456789123456789</td>-->
<!--        <td colspan="2">签订劳动合同：XXXX公司</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">序号</td>-->
<!--        <td class="tde" colspan="2">信息内容</td>-->
<!--        <td class="tdc">记录时间</td>-->
<!--        <td class="tdc">信息记录单位</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.2</td>-->
<!--        <td class="tde" rowspan="5"></td>-->
<!--        <td class="tde">姓名：李雷</td>-->
<!--        <td class="tdc" rowspan="17"></td>-->
<!--        <td class="tdc" rowspan="17"></td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.3</td>-->
<!--        <td class="tde">性别：男</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.4</td>-->
<!--        <td class="tde">民族：汉</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.5</td>-->
<!--        <td class="tde">出生日期：1991.9.12</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.6</td>-->
<!--        <td class="tde">政治面貌：群众</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.7</td>-->
<!--        <td class="tdb" colspan="2">住址：广东省肇庆市</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.8</td>-->
<!--        <td class="tdb" colspan="2">联系电话：13770993444</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.9</td>-->
<!--        <td class="tdb" colspan="2">应急联系人：韩梅梅</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.10</td>-->
<!--        <td class="tdb" colspan="2">应急联系电话：18909098888</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.11</td>-->
<!--        <td class="tdb" colspan="2">血型：0</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.12</td>-->
<!--        <td class="tdb" colspan="2">教育程度:高中</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.13</td>-->
<!--        <td class="tdb" colspan="2">资格证书：无</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.14</td>-->
<!--        <td class="tdb" colspan="2">工种：泥工</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.15</td>-->
<!--        <td class="tdb" colspan="2">健康情况：良好</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.16</td>-->
<!--        <td class="tdb" colspan="2">劳动合同：有</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.17</td>-->
<!--        <td class="tdb" colspan="2">工伤保险：有</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">1.18</td>-->
<!--        <td class="tdb" colspan="2">意外保险：有</td>-->
<!--      </tr>-->
<!--      <tr class="part-title">-->
<!--        <td colspan="5">2.工人进场信息</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">2.1</td>-->
<!--        <td class="tdb" colspan="2">进场时间：2024.3.21</td>-->
<!--        <td class="tdc" rowspan="3"></td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">2.2</td>-->
<!--        <td class="tdb" colspan="2">所属班组：梁场</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">2.3</td>-->
<!--        <td class="tdb" colspan="2">工资卡号：6213140547598375043</td>-->
<!--      </tr>-->
<!--      <tr class="part-title">-->
<!--        <td colspan="5">3.工人退场信息</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">3.1</td>-->
<!--        <td class="tdb" colspan="2">退场时间：2024.3.21</td>-->
<!--        <td class="tdc" rowspan="2"></td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">3.2</td>-->
<!--        <td class="tdb" colspan="2">完成工种内容：</td>-->
<!--      </tr>-->
<!--      <tr class="part-title">-->
<!--        <td colspan="5">4.培训教育信息</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">4.1</td>-->
<!--        <td class="tde" rowspan="2">进场培训</td>-->
<!--        <td class="tdf" colspan="3">培训课时：</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">4.2</td>-->
<!--        <td class="tdf" colspan="3">培训内容：</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">4.3</td>-->
<!--        <td class="tde" rowspan="2">在岗培训</td>-->
<!--        <td class="tdf" colspan="3">培训课时：</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">4.4</td>-->
<!--        <td class="tdf" colspan="3">培训内容：</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">4.5</td>-->
<!--        <td class="tdj" colspan="4">安全技术交底：</td>-->
<!--      </tr>-->
<!--      <tr class="part-title">-->
<!--        <td colspan="5">5.严重不良行为信息</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">5.1</td>-->
<!--        <td class="tdh" colspan="3">严重不良行为情况：</td>-->
<!--        <td class="tdc" rowspan="3"></td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">5.2</td>-->
<!--        <td class="tdh" colspan="3">严重不良行为记录人：</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="tda">5.3</td>-->
<!--        <td class="tdh" colspan="3">处罚情况：</td>-->
<!--      </tr>-->
<!--    </table>-->
<!--  </div>-->
</template>

<script>
export default {
  name: "index",
  created(){
    let  imei =  this.$route.query.imei;
    window.location.href = "https://iot.suogong.net/device/qr?imei="  + imei

  }
}
</script>

<style scoped>
body {
  font-size: 12px;
  padding: 0 0px;
  margin: 0;
  font-weight: 400;
  color: #000000;
}

.table-title {
  text-align: center;
  padding: 20px 0;
}

table {
  width: auto;
  margin: 0 auto;
  border-collapse: collapse;
}

td {
  padding: 1px;
  height: 42px;
  box-sizing: border-box;
}

.tda {
  width: 50px;
}

.tdb {
  width: 300px;
}

.tdc {
  width: 175px;
}

.tde {
  width: 150px;
}

.tdf {
  width: 500px;
}

.tdj {
  width: 650px;
}

.tdh {
  width: 475px;
}

.part-title {
  text-align: center;
  font-weight: 700;
}
</style>
